<template>
  <v-container class="fill-height">
    <v-row class="ma-0 font-weight-bold">
      Si estás teniendo problemas para realizar tu pago, intenta refrescar la
      página con Ctrl + Shift + R.
    </v-row>
    <v-row justify="center">
      <v-col cols="12" class="white">
        <v-row justify="center">
          <v-col>
            <v-tabs
              :fixed-tabs="$vuetify.breakpoint.smAndDown"
              :color="color.primaryVariant"
              :value="tabSwitch"
              class="font-weight-bold"
            >
              <v-tab :disabled="!hasDebts">
                <span class="body-2 font-weight-bold"> Cuotas Atrasadas </span>
              </v-tab>
              <v-tab :disabled="hasDebts">
                <span class="body-2 font-weight-bold"> Cuota Actual </span>
              </v-tab>

              <v-tab-item v-if="hasDebts">
                <div v-if="debtStep == 0">
                  <v-row justify="center" class="ma-0 my-4">
                    <v-col cols="8">
                      <span class="text-body-2">
                        <v-icon color="blue darken-3">mdi-alert</v-icon>
                        Tienes los siguientes adeudos pendientes, una vez
                        cubiertos podrás ir a la siguiente pestaña a realizar tu
                        pago de la cuota actual.
                      </span>
                    </v-col>
                  </v-row>

                  <v-row
                    justify="center"
                    class="ma-0"
                    v-for="debt in debts"
                    :key="debt._id"
                  >
                    <v-col cols="8">
                      <v-row justify="space-between">
                        <v-col cols="7" class="text-end">
                          <span> {{ debt.description }} </span>
                        </v-col>

                        <v-col cols="3">
                          <span class="text-body-2">
                            $ {{ formatAmount(debt.amount) }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row justify="center" class="ma-0">
                    <v-col cols="8">
                      <v-row justify="space-between">
                        <v-col cols="7" class="text-end font-weight-black">
                          <span> TOTAL </span>
                        </v-col>

                        <v-col cols="3">
                          <span class="text-body-2 font-weight-bold">
                            $ {{ formatAmount(totalAmount) }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="text-center">
                      <v-btn
                        color="blue darken-2"
                        class="white--text px-6"
                        rounded
                        @click="createOrder()"
                      >
                        Pagar
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>

                <div v-if="debtStep == 1">
                  <DebtCheckout :orderProp="order">
                    <v-row
                      justify="center"
                      class="ma-0"
                      v-for="debt in debts"
                      :key="debt._id"
                    >
                      <v-col cols="8">
                        <v-row justify="space-around">
                          <v-col cols="7" class="text-start">
                            <span> {{ debt.description }} </span>
                          </v-col>

                          <v-col cols="3">
                            <span class="text-body-2">
                              $ {{ formatAmount(debt.amount) }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row justify="center" class="ma-0">
                      <v-col cols="8">
                        <v-row justify="space-around">
                          <v-col cols="7" class="font-weight-black">
                            <span> TOTAL </span>
                          </v-col>

                          <v-col cols="3">
                            <span class="text-body-2 font-weight-bold">
                              $ {{ formatAmount(totalAmount) }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </DebtCheckout>
                </div>
              </v-tab-item>

              <v-tab-item v-else>
                <v-row justify="center" class="ma-0 my-4">
                  <v-col cols="8">
                    <span class="text-body-2">
                      <v-icon x-large color="green darken-3">mdi-check</v-icon>
                      No tienes ningún adeudo pendiente, puedes ir a la
                      siguiente pestaña a realizar tu pago de la cuota actual.
                    </span>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item v-if="!hasDebts">
                <ContinuePayment
                  :setStep="$route.params.setStep"
                  :affiliationId="$route.params.affiliationId"
                  :membershipId="$route.params.membershipId"
                >
                </ContinuePayment>
              </v-tab-item>

              <v-tab-item v-else>
                <v-row justify="center" class="ma-0 my-4">
                  <v-col cols="8">
                    <span class="text-body-2">
                      <v-icon x-large color="red darken-3"
                        >mdi-close-thick</v-icon
                      >
                      No puedes continuar con el proceso de pago hasta que
                      cubras tus adeudos pendientes.
                    </span>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-btn
          :color="color.primaryVariant"
          class="text-uppercase rounded-pill white--text font-weight-bold"
          :to="getBackToProfiles().route"
        >
          <v-icon>mdi-chevron-left</v-icon>
          {{ getBackToProfiles().label }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { colors } from "@/constants/colors.js";
import DebtCheckout from "./debtCheckout.vue";
import ContinuePayment from "../newAffiliate/index.vue";
import { mapState } from "vuex";
export default {
  name: "Payments",
  components: {
    DebtCheckout,
    ContinuePayment,
  },
  data() {
    return {
      color: colors,
      debts: [],
      order: {},
      debtStep: 0,
    };
  },
  computed: {
    ...mapState("membershipProfile", ["userMembership"]),
    ...mapState("user", ["user"]),
    hasDebts() {
      return this.debts.length > 0;
    },

    totalAmount() {
      let total = 0;
      this.debts.forEach((debt) => {
        total += debt.amount;
      });
      return total;
    },

    tabSwitch() {
      return this.debts.length > 0 ? 2 : 1;
    },
  },
  methods: {
    getBackToProfiles() {
      console.log(this.user);
      if (
        (this.validateOrganization() && this.userMembership) ||
        (!this.validateOrganization() && !this.userMembership)
      ) {
        return { label: "Volver al inicio", route: "/member/home" };
      } else if (!this.userMembership && this.validateOrganization()) {
        return {
          label: "Volver a la selección de perfiles",
          route: "/member/affiliation-profile",
        };
      }
      return { label: "Volver al inicio", route: "/member/home" };
    },

    // validates if user organization is ampi (dev || prod)
    validateOrganization() {
      return ["62b622c66a7441ae20159d7d", "6297b0fec7bf8b6f826313be"].includes(
        this.user.headOffice._id
      );
    },

    async getMemberDebts() {
      //uses axios to get member debts
      try {
        const debtsResponse = await this.axios({
          method: "GET",
          url: "debts",
        });

        this.debts = debtsResponse.data.debts;
        console.log(this.debts);
      } catch (error) {
        console.log(error);
      }
    },

    async createOrder() {
      //uses axios to create order
      try {
        const orderResponse = await this.axios({
          method: "POST",
          url: "debts/order-management",
        });

        this.order = orderResponse.data.order;

        this.increaseDebtStep();
      } catch (error) {
        console.log(error);
      }
    },

    increaseDebtStep() {
      this.debtStep++;
    },

    formatAmount(amount) {
      let formattedAmount = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      formattedAmount += ".00";
      return formattedAmount;
    },
  },

  created() {
    this.getMemberDebts();
  },
};
</script>

<style></style>